.projects-map {
    height: 100%;
    margin: 0 -12px;
    overflow: hidden;

    @media screen and (min-width: $breakpoint-tablet) {
        margin: 0;
    }

    .leaflet-left.leaflet-bottom {
        right: 0;
    }
}

.project-overlay-control {
    background: $white;
    position: relative;
    z-index: 1000;
    padding: 0.5em;
    margin-left: 0;

    @media screen and (min-width: $breakpoint-tablet) {
        display: none;
    }

    a {
        color: $text-base;
    }
}

.leaflet-left .project-overlay-control.leaflet-control {
    margin: 0 1em 1em;
    float: none;
}

.projects-map__popup {
    display: none;

    @media screen and (min-width: $breakpoint-tablet) {
        display: block;

    }

    .leaflet-popup-content-wrapper {
        border-radius: 0;
    }

    .leaflet-popup-content {
        margin: 1.25em;
    }

    .leaflet-popup-content a {
        color: $text-base;
    }

    .leaflet-popup-tip-container {
        display: none;
    }
}

.projects-map__search.leaflet-control {
    z-index: 1001;
}
