.toggle-switch {
    overflow: hidden;
}

.toggle-switch__label {
    margin-right: .4em;
}

.toggle-switch__label--right {
    margin-left: .4em;
    margin-right: 0;
}
