.search-profiles-list__title {
    margin-bottom: 1rem;

    @media screen and (min-width: $breakpoint-palm) {
        margin-bottom: 1.5rem;
    }
}

.search-profile {
    border-bottom: 1px solid $gray-lighter;
    padding-bottom: 1rem;
    padding-top: 1rem;

    @media screen and (min-width: $breakpoint-palm) {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
    }
}

.search-profile__title {
    margin-bottom: 1rem;
    margin-top: 0;

    @media screen and (min-width: $breakpoint-palm) {
        margin-bottom: 0.25rem;
    }
}

.search-profile__title:last-child {
    margin-bottom: 0;
}

.search-profile__filters {
    color: $gray-darkest;
    font-size: 0.9rem;
    margin-bottom: 0;
    padding-left: 0;
}

.search-profile__filter {
    display: inline-block;
    list-style: none;
    margin-right: 1rem;
    margin-bottom: 0;
}

.search-profile__header {
    display: flex;
    gap: 3rem;
    margin-bottom: 0.5rem;

    @media screen and (min-width: $breakpoint-palm) {
        margin-bottom: 1.2rem;
    }
}

.search-profile__header-buttons {
    display: none;

    @media screen and (min-width: $breakpoint-palm) {
        display: block;
        margin-left: auto;
    }
}

.search-profile__buttons {
    display: flex;
    gap: 1.5rem;
}

.search-profile__button {
    padding: 0;
    margin: 0;
    font-size: $em-spacer;
    line-height: 1.2;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    color: $text-base;
}

.search-profile__button:hover {
    text-decoration: underline;
}

.search-profile__form {
    margin-bottom: 1.2rem;
}

.search-profile__footer {
    @media screen and (min-width: $breakpoint-palm) {
        display: flex;
        align-items: flex-start;
    }
}

.search-profile__view-projects {
    display: block;

    @media screen and (min-width: $breakpoint-palm) {
        display: inline;
        margin-left: auto;
    }
}

.search-profile__footer-buttons {
    display: flex;
    margin-top: 1rem;

    @media screen and (min-width: $breakpoint-palm) {
        display: none;
    }
}

.search-profile__footer-buttons div:only-of-type {
    margin-left: auto;
}

.search-profile__toggle-switch {
    display: flex;
    margin-bottom: 1rem;

    @media screen and (min-width: $breakpoint-tablet-landscape) {
        margin-bottom: 0;
    }
}

.search-profile__toggle-switch label {
    order: 1;
}

.search-profile__toggle-switch .toggle-switch__display {
    transform: scale(0.75);
    transform-origin: left;
}

.search-profile__toggle-switch .toggle-switch__label {
    margin-left: -1.25rem;
}

.save-search-profile {
    margin-bottom: 0.9375rem;
    text-align: right;

    @media screen and (min-width: $breakpoint-palm) {
        margin-bottom: 0;
        min-width: 12.5rem;
    }
}

.save-search-profile__action {
    color: $gray-darkest;
    line-height: 1.5rem;
    flex-shrink: 0;
}

.save-search-profile__action--link,
.save-search-profile__action--button {
    color: $link-color;
}

.save-search-profile__action--button {
    padding: 0;

    &:hover {
      text-decoration: underline;
    }
}

.save-search-profile__error {
    color: $text-error
}
