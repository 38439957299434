.ck .ck-toolbar {
  border: 2px solid black !important;
  border-bottom: none !important;
}

.ck .ck-editor__editable {
  border: 2px solid black !important;
}

img.image_resized {
  height: auto;
}
